import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import * as R from "ramda";

import { Combobox, IconMarker } from "@jasperlabs/jux-next";

import {
	queries,
	useLazyQueryOnError,
	useLazyQueryOnSuccess,
} from "../../../api";

import usePlacesAutocompleteStateMachine from "./usePlacesAutocompleteStateMachine";

const validationFor = {
	ADDRESS: R.compose(R.all(Boolean), R.props(["city", "country", "line1"])),
	CITIES: R.compose(R.all(Boolean), R.props(["city", "country"])),
};

const PlacesAutocomplete = ({
	onValues,
	searchType,
	onBlur,
	defaultValues,
	onGeocodeError,
	onGeocodeValidationError,
	onSearchError,
	onRawSearchChange,
	noOptionsMessage,
	...rest
}) => {
	const geocodeMutation = useLazyQuery(queries.GEOCODE_PLACE_ID);
	const [geocodePlace] = geocodeMutation;
	const searchLazyQuery = useLazyQuery(queries.SEARCH_ADDRESS);
	const [fetchSearchAddress] = searchLazyQuery;

	const [current, send] = usePlacesAutocompleteStateMachine({
		initialContext: defaultValues,
		actions: {
			onGeocodeError,
			onGeocodeValidationError,
			onSearchError,
			onRawSearchChange,
			fetchSearchAddress({ rawSearch, sessionToken }) {
				fetchSearchAddress({
					variables: {
						sessionToken,
						query: rawSearch,
						searchType,
					},
				});
			},
			fetchGeocodePlace({ placeId, sessionToken }) {
				if (placeId) {
					geocodePlace({
						variables: {
							placeId,
							sessionToken,
						},
					});
				}
			},
		},
	});

	const placeState = current.context;
	const { searchResults = [] } = placeState;
	const isLoading =
		current.matches({ search: "pending" }) ||
		current.matches({ geocode: "pending" });

	useEffect(() => {
		const filterPropsFrom = R.pick([
			"description",
			"rawSearch",
			"placeId",
			"line1",
			"suburb",
			"postCode",
			"administrativeArea",
			"city",
			"country",
		]);
		const filteredProps = filterPropsFrom(placeState);
		onValues(filteredProps);
	}, [placeState, onValues]);

	useLazyQueryOnSuccess(({ geocodePlaceId: addressData }) => {
		const validator = validationFor[searchType];

		if (validator(addressData)) {
			send({ type: "GEOCODE_SUCCESS", payload: addressData });
		} else {
			send({ type: "GEOCODE_VALIDATION_FAILURE", payload: addressData });
		}
	}, geocodeMutation);

	useLazyQueryOnError(() => {
		send({ type: "GEOCODE_FAILURE" });
	}, geocodeMutation);

	useLazyQueryOnSuccess(({ searchAddress: searchResult }) => {
		const { sessionToken: token, results } = searchResult;
		send({ type: "SEARCH_SUCCESS", payload: { token, results } });
	}, searchLazyQuery);

	useLazyQueryOnError(() => {
		send({ type: "SEARCH_FAILURE" });
	}, searchLazyQuery);

	const handleSearch = (rawSearchValue) => {
		send({ type: "RAW_SEARCH", payload: rawSearchValue });
	};

	const handleSelect = ({ placeId, description }) => {
		send({ type: "SELECT", payload: { placeId, description } });
	};

	return (
		<Combobox
			multiple={false}
			iconDropdownIndicator={() => null}
			onChange={handleSelect}
			onSearch={handleSearch}
			onBlur={onBlur}
			value={placeState || defaultValues}
			displayValue={(value) => value?.description ?? ""}
			loading={isLoading}
			{...rest}
		>
			{isLoading && (
				<Combobox.OptionsPlaceholder>Loading...</Combobox.OptionsPlaceholder>
			)}

			{!isLoading && searchResults.length === 0 ? (
				<Combobox.OptionsPlaceholder>
					{noOptionsMessage}
				</Combobox.OptionsPlaceholder>
			) : (
				searchResults.map((result) => {
					const [primary, ...remaining] = result.description.split(",");

					return (
						<Combobox.Option key={result.placeId} value={result}>
							<div
								className="flex items-center"
								aria-label={result.description}
							>
								<IconMarker className="ui-selected:text-white text-xl text-neutral-300" />
								<div className="ml-2 flex-1">
									<span className="text-body-sm">{primary}</span>
									{remaining && (
										<span className="text-body-xs text-neutral-400">
											, {remaining.join(",")}
										</span>
									)}
								</div>
							</div>
						</Combobox.Option>
					);
				})
			)}
		</Combobox>
	);
};

PlacesAutocomplete.defaultProps = {
	onBlur() {},
	defaultValues: {},
	onSearchError() {},
	onGeocodeError() {},
	onGeocodeValidationError() {},
	onRawSearchChange() {},
	searchType: "ADDRESS",
	noOptionsMessage: "No results found",
};

PlacesAutocomplete.propTypes = {
	onValues: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	onSearchError: PropTypes.func,
	onGeocodeError: PropTypes.func,
	onGeocodeValidationError: PropTypes.func,
	onRawSearchChange: PropTypes.func,
	searchType: PropTypes.oneOf(["ADDRESS", "CITIES"]),
	noOptionsMessage: PropTypes.node,
	defaultValues: PropTypes.shape({
		description: PropTypes.string,
		placeId: PropTypes.string,
	}),
};

export default PlacesAutocomplete;
