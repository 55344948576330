import React, { useCallback } from "react";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";

import { FormField } from "@jasperlabs/jux-next";

import { getNestedObjectByKey } from "components/utilities/getNestedObjectByKey";

import PlacesAutocomplete from "../PlacesAutocomplete";
import { AddressSearchFormValues } from "./types";

type Props = {
	groupKey: string;
	label: string;
	noOptionsMessage: React.ReactNode;
	hint: React.ReactNode;
	onSearchError?: () => void;
};

const AddressAutoComplete = ({
	groupKey,
	label,
	noOptionsMessage,
	hint,
	onSearchError,
}: Props) => {
	const { setFieldValue, setFieldTouched, ...formikContext } =
		useFormikContext<{
			[groupKey: string]: AddressSearchFormValues;
		}>();

	const touched = getNestedObjectByKey<FormikTouched<AddressSearchFormValues>>(
		formikContext.touched,
		groupKey,
	);

	const errors = getNestedObjectByKey<FormikErrors<AddressSearchFormValues>>(
		formikContext.errors,
		groupKey,
	);

	const isTouched = touched?.placeId ?? false;
	const error = errors?.placeId;

	const handleAddressAutocompleteChange = useCallback(
		({
			rawSearch,
			placeId,
			line1,
			suburb,
			city,
			postCode,
			administrativeArea,
			country,
		}: any) => {
			setFieldValue(`${groupKey}.placeId`, placeId);
			setFieldValue(`${groupKey}.rawSearch`, rawSearch);
			setFieldValue(`${groupKey}.addressLine1`, line1);
			setFieldValue(`${groupKey}.suburb`, suburb);
			setFieldValue(`${groupKey}.city`, city);
			setFieldValue(`${groupKey}.postCode`, postCode);
			setFieldValue(`${groupKey}.administrativeArea`, administrativeArea);
			setFieldValue(`${groupKey}.country`, country);
		},
		[setFieldValue, groupKey],
	);

	const currentValues = getNestedObjectByKey(formikContext.values, groupKey);
	const hasDefaultValues = currentValues?.placeId;
	const { addressLine1, suburb, city, postCode, country } = currentValues || {};

	return (
		<FormField
			id="placeId"
			label={label}
			hasError={error !== undefined && isTouched}
			error={error}
			hint={hint}
		>
			<PlacesAutocomplete
				data-testid="places-auto-complete"
				id="placeId"
				name="placeId"
				searchType="ADDRESS"
				label={label}
				onValues={handleAddressAutocompleteChange}
				noOptionsMessage={noOptionsMessage}
				onBlur={() => setFieldTouched(`${groupKey}.placeId`, true)}
				onSearchError={onSearchError}
				onGeocodeValidationError={onSearchError}
				defaultValues={
					hasDefaultValues && {
						...currentValues,
						description: [addressLine1, suburb, city, postCode, country].join(
							", ",
						),
					}
				}
				onGeocodeError={onSearchError}
				onRawSearchChange={undefined}
			/>
		</FormField>
	);
};

export default AddressAutoComplete;
