import { createMachine, assign } from "xstate";
import * as R from "ramda";
import { useMachine } from "@xstate/react";

const MIN_CHARACTERS = 3;

const DEFAULT_CONTEXT = {
	description: "",
	rawSearch: null,
	placeId: null,
	line1: "",
	suburb: "",
	postCode: "",
	city: "",
	country: "",
	administrativeArea: null,
	sessionToken: null,
	searchResults: [],
};

const safeDefault = R.compose(
	R.mapObjIndexed((v, k) => v ?? DEFAULT_CONTEXT[k]),
	R.defaultTo({}),
);
const mergeWithDefault = R.compose(safeDefault, R.mergeRight(DEFAULT_CONTEXT));

const isSearchLongEnough = (_, event) => event.payload.length >= MIN_CHARACTERS;
const onSearchSuccess = assign((context, event) => ({
	...context,
	sessionToken: event.payload?.token,
	searchResults: event.payload?.results,
}));
const onRawSearch = assign((context, event) => ({
	...DEFAULT_CONTEXT,
	sessionToken: context.sessionToken,
	rawSearch: event.payload,
}));
const onSelect = assign((context, event) => ({
	...DEFAULT_CONTEXT,
	sessionToken: context.sessionToken,
	rawSearch: context.rawSearch,
	...safeDefault(event.payload),
}));
const onGeocodeSuccess = assign((context, event) => ({
	...DEFAULT_CONTEXT,
	sessionToken: null,
	rawSearch: context.rawSearch,
	placeId: context.placeId,
	description: context.description,
	...safeDefault(event.payload),
}));
// On validation failure we still want to assign the result to state.
const onGeocodeValidationFailure = onGeocodeSuccess;
const onGeocodeFailure = assign((context) => ({
	...context,
	sessionToken: null,
}));

const usePlacesAutocompleteStateMachine = ({
	initialContext = {},
	actions,
	guards,
}) =>
	useMachine(
		createMachine({
			id: "places-autocomplete",
			context: mergeWithDefault(initialContext),
			type: "parallel",
			on: {
				RAW_SEARCH: {
					actions: [onRawSearch, "onRawSearchChange"],
				},
			},
			states: {
				search: {
					initial: "idle",
					states: {
						idle: {
							on: {
								RAW_SEARCH: {
									target: "pending",
									cond: isSearchLongEnough,
									actions: [
										onRawSearch,
										"onRawSearchChange",
										"fetchSearchAddress",
									],
								},
							},
						},
						pending: {
							on: {
								SEARCH_SUCCESS: {
									target: "idle",
									actions: [onSearchSuccess],
								},
								SEARCH_FAILURE: {
									target: "idle",
									actions: ["onSearchError"],
								},
								RAW_SEARCH: {
									target: "",
									cond: isSearchLongEnough,
									actions: [
										onRawSearch,
										"onRawSearchChange",
										"fetchSearchAddress",
									],
								},
							},
						},
					},
				},
				geocode: {
					initial: "idle",
					states: {
						idle: {
							on: {
								SELECT: {
									target: "pending",
									actions: [onSelect, "fetchGeocodePlace"],
								},
							},
						},
						pending: {
							on: {
								GEOCODE_SUCCESS: {
									target: "idle",
									actions: [onGeocodeSuccess],
								},
								GEOCODE_VALIDATION_FAILURE: {
									target: "idle",
									actions: [
										onGeocodeValidationFailure,
										"onGeocodeValidationError",
									],
								},
								GEOCODE_FAILURE: {
									target: "idle",
									actions: [onGeocodeFailure, "onGeocodeError"],
								},
								SELECT: {
									target: "",
									actions: [onSelect, "fetchGeocodePlace"],
								},
							},
						},
					},
				},
				list: {
					initial: "closed",
					states: {
						closed: {
							on: {
								RAW_SEARCH: {
									target: "open",
									cond: isSearchLongEnough,
								},
							},
						},
						open: {
							on: {
								SELECT: "closed",
								CLOSE: "closed",
							},
						},
					},
				},
			},
		}),
		{
			actions,
			guards,
		},
	);

export default usePlacesAutocompleteStateMachine;
